import React from 'react';
import { Container, Typography, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import CartItem from './CartItem/CartItem';
import useStyles from './styles';

import {useTranslation} from 'react-i18next';

const Cart = ({ cart, onUpdateCartQty, onRemoveFromCart, onEmptyCart }) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const handleEmptyCart = () => onEmptyCart();

  const renderEmptyCart = () => (
    <Typography variant="subtitle1">{t("emptyCart1")}
      <Link className={classes.link} to={"/"}> {t("emptyCart2")}</Link>!
    </Typography>
  );

  if (!cart.line_items) return 'Loading';
  console.log(cart.line_items);

  const renderCart = () => (
    <>
      <Grid container spacing={4}>
        {cart.line_items.map((lineItem) => (
          <Grid item key={lineItem.productID} item xs={12} sm={12} md={12} lg={12}>
            <CartItem item={lineItem} onUpdateCartQty={onUpdateCartQty} onRemoveFromCart={onRemoveFromCart} />
          </Grid>
        ))}
      </Grid>
      <div className={classes.cardDetails}>
        <Typography className={classes.subTot} variant="h4">{t("subtotal")}: S${cart.subtotal.toFixed(2)}</Typography>
        <div>
          <Button className={classes.emptyButton} size="large" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>{t("btnEmptyCart")}</Button>
          <Button className={classes.checkoutButton} component={Link} to="/checkout" size="large" type="button" variant="contained" style={{
        backgroundColor: "#2ACB96",
        color: 'white'
    }}>{t("btnCheckout")}</Button>
        </div>
      </div>
      <div className={classes.spacer} />
      <div>Accepted Payment modes:<br  />
        <img className={classes.payment} src="https://lh3.googleusercontent.com/ma1Bg2mFr5ES8Zy-9A6EGytLCo3j7tnm53ER08PMOgMSExUkFtYPdW2qq9faY4r3OkcfKa2LLspozyA5w3QUv4yD4xbPNoX9iBL25F8xjJFLK_wGMmo_jYI52-GdXu5RWA=w79" height={30} />
        <img className={classes.payment} src="https://lh5.googleusercontent.com/WM_r0MvvigsEc4ggwazR2SInD28MaQ3E4ETcRN6s1hK6lG5Lva9RtTCg83Z-GiGBVsPCLBwMFwWu1KrxxxrDOaBzifTnOS-h8H9uG4XpHQvDy1xSFg15e9O-fN_QITScag=w50" height={40} />
        <img className={classes.payment} src="https://lh6.googleusercontent.com/vE0YYppig1GxASUCZUTID-LbZz6t71z0H3m2tsxy08KeM7Sv1ZmBZUCB3bo_qQM6vSKSMeJhfvwgzeU1Wj1xgDkqbwR_yHKal3sK48a4Qkgy97TNvp0lFptWC_QHCrN1kg=w127" height={30} />
      </div>
      <div className={classes.spacer} />
    </>
  );

  return (
    <Container className={classes.cartContainer}>
      <div className={classes.toolbar} />
      <Typography className={classes.note} variant="subtitle1" gutterBottom>{t("imptMsg")}</Typography>
      <Typography className={classes.title} variant="h4" gutterBottom>{t("cartTitle")}</Typography>
      { !cart.line_items.length ? renderEmptyCart() : renderCart() }
      <div className={classes.spacer} />
    </Container>
  );
};

export default Cart;
