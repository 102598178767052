import React, { useState } from 'react';
import { CssBaseline, Paper, Stepper, Step, StepLabel, Typography, CircularProgress, Divider, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import CustomerForm from '../CustomerForm';
import CollectionForm from '../CollectionForm';
import FeedbackForm from '../FeedbackForm';
import SubmittedForm from '../SubmittedForm';
import useStyles from './styles';
import {useTranslation} from 'react-i18next';
import { API_URL } from '../../commons';

const Checkout = ({ cart, handleEmptyCart, order, peakPeriod, promocodes, error }) => {
  const {t, i18n} = useTranslation();
  const steps = [t("customerInfo"), t("collectionMethod"), t("feedback"), t("finish")];
  // const [checkoutToken, setCheckoutToken] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [customerData, setCustomerData] = useState({});
  const [collectionData, setCollectionData] = useState({});
  const [feedbackData, setFeedbackData] = useState({});
  const [submittedOrderNo, setSubmittedOrderNo] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const classes = useStyles();
  // const history = useHistory();

  const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleResize = (e) => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleResize);

  const populateCustomerData = (data) => {
    if (cart.subtotal === 0) {
      alert(t("emptyCart1"));
      return;
    }

    setCustomerData(data);
    // console.log('populateCustomerData');
    // console.log(data);
    
    nextStep();
  };

  const populateCollectionData = (data) => {
    if (cart.subtotal === 0) {
      alert(t("emptyCart1"));
      return;
    }

    setCollectionData(data);
    // console.log('populateCollectionData');
    // console.log(data);

    nextStep();
  };

  const populateFeedbackData = (data) => {
    if (cart.subtotal === 0) {
      alert(t("emptyCart1"));
      return;
    }
    
    setFeedbackData(data);
    // console.log('populateFeedbackData');
    // console.log(data);

    // console.log('submit order..');
    // console.log(customerData);
    // console.log(collectionData);
    // console.log(data);

    var mergedObj = {...customerData,...collectionData,...data,orders:cart, language:i18n.language};
    // console.log(mergedObj);

    // {senderName: 'heidi', senderContact: '96795458', checkedSame: true}
    // {collectionPlace: 'balestier', chosenDate: '2021-10-21'}
    // {knowUs: '', feedback: 'Awesome', checkedFB: false, checkedIG: false, checkedMouth: true}
    fetch(API_URL+"/createOrder", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
      },
      "body": JSON.stringify(mergedObj)
    })
    .then(response => response.json())
    .then(response => {
      // console.log(response.OrderNo);
      setSubmittedOrderNo(response.OrderNo);
      handleEmptyCart();
      nextStep();
    })
    .catch(err => {
      console.log(err);
    });
  };

  let Confirmation = () => (order.customer ? (
    <>
      <div>
        <Typography variant="h5">Thank you for your purchase, {order.customer.firstname} {order.customer.lastname}!</Typography>
        <Divider className={classes.divider} />
        <Typography variant="subtitle2">Order ref: {order.customer_reference}</Typography>
      </div>
      <br />
      <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
    </>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  ));

  if (error) {
    Confirmation = () => (
      <>
        <Typography variant="h5">Error: {error}</Typography>
        <br />
        <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
      </>
    );
  }

  const Form = () => {
    switch (activeStep) {
      case 0:
        return (<CustomerForm next={populateCustomerData} customerData={customerData} />);
      case 1:
        return (<CollectionForm next={populateCollectionData} peakPeriod={peakPeriod} promocodes={promocodes} collectionData={collectionData} backStep={backStep} />);  
      case 2:
          return (<FeedbackForm next={populateFeedbackData} feedbackData={feedbackData} backStep={backStep} />);  
      default:
        return (<SubmittedForm orderNo={submittedOrderNo}/>);  
        // return (<PaymentForm checkoutToken={checkoutToken} nextStep={nextStep} backStep={backStep} shippingData={shippingData} onCaptureCheckout={onCaptureCheckout} />);
    }
  }


  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.imptMsg} align="center">{t("imptMsg")}</Typography><br />
          <Typography variant="h4" className={classes.title} align="center">{t("btnCheckout")}</Typography>
          <Stepper className={classes.stepperCircle} activeStep={activeStep} className={classes.stepper} orientation={windowWidth<500?'vertical':'horizontal'}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className={classes.stepperLabel}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {/* {activeStep === steps.length ? <Confirmation /> : checkoutToken && <Form />} */}
          <Form />
        </Paper>
      </main>
    </>
  );
};

export default Checkout;
