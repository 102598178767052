import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    // maxWidth: 345, original width style
    maxWidth: '100%',
    padding: 10,
  },
  cat1: {
    backgroundColor: '#fff0f7',
    padding: 10,
  },
  cat2: {
    backgroundColor: '#f5f5f5',
    padding: 10,
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
  mediaCarousel: {
    height: '100%',
    width: '100%'
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  categoryTitle: {
    fontSize: 20,
    margin: 20,
    fontFamily: 'Fredoka One',
    color: '#ff99cc',
    letterSpacing: -0.5,
    lineHeight: 1
  },
  categoryDesc: {
    fontSize: 14,
    fontWeight: "500",
    margin: 20,
  },
  categorySection: {
    display: 'flex',
    flexDirection: 'row'
  },
  '@media (max-width: 480px)': {
    categorySection: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  categorySpace: {
    flex: 2
  },
  categorySearch: {
    flex: 1
  },
  productTitle: {
    fontSize: 18,
    fontWeight: "700",
  },
  productPrice: {
    fontSize: 14,
  },
  productPromo: {
    fontSize: 14,
    color: "#FF68DB"
  },
  productDesc: {
    fontSize: 14,
    color: 'grey',
    marginTop: 5
  },
  customSegment: {
    background: 'white',
    color: 'white',
    fontSize: 14
  },
  checkbox: {
    margin: '0px',
    width: '100%',
    fontSize: 6
  },
  cartIcon: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
}));
