import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  carousel: {
    height: '100%',
    display: 'flex',
    justifyContent:'center',
    // backgroundImage: "url('/img/bannerTile.jpg')",
    // backgroundRepeat: 'repeat',
    // backgroundSize: '60px 60px',
    backgroundColor: '#FFCCCC'
  },
  bannerImg: {
    backgroundImage: "url('/img/Banner2022C5.png')",
    height: '250px',
    width: '100%',
    maxWidth: '1400px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  '@media (max-width: 480px)': {
    bannerImg: {
      backgroundImage: "url('/img/Banner2022Csm.png')",
      height: '200px',
      maxWidth: '280px',
    }
  },
  spacer: {
    paddingBottom: 50,
  },
}));
