import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, Grid, Radio } from '@material-ui/core';
import promocode from '../../assets/promodelivery.json';
import {useTranslation} from 'react-i18next';

const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
function FormInput({ name, label, required, errorText, contactType, promocodeType, postalType, emailType, cardType}) {
  
  const {t} = useTranslation();
  const { control } = useFormContext();

  var rules = {
    validate: {
      emptyValue: value => value.trim().length > 0 || !required
    }
  };

  if (contactType) {
    rules.validate.invalidContact = value => (value.trim()).length >= 8 && value.trim().match(/\D/) === null?true:false;
  }

  if (promocodeType) {
    rules.validate.invalidPromocode = value => value.trim() === promocode.code || !required;
  }

  if (postalType) {
    rules.validate.invalidPostal = value => (value.trim()).length === 6 && value.trim().match(/\D/) === null?true:false;
  }

  if (emailType) {
    rules.validate.invalidEmail = value => value.match(mailformat) === null?false:true;
  }

  if (cardType) {
    rules.validate.invalidCard = value => (value.trim()).length < 130;
  }


  return (
    <Grid item xs={12} sm={6}>
      <Controller
        as={TextField}
        name={name}
        control={control}
        label={label}
        fullWidth
        rules={rules}
        required={required}
      />
      { errorText !== "invalidPromocode" && name === "promocode" && errorText === "emptyValue" &&
      <span>{promocode.msg}</span> }
      { errorText === "emptyValue" && required &&
      <span>{t("vEmptyVal")}</span> }
      { errorText === "invalidContact" &&
      <span>{t("vInvalidContact")}</span> }
      { errorText === "invalidPromocode" &&
      <span>{t("vInvalidPromocode")}</span> }
      { errorText === "invalidPostal" &&
      <span>{t("vInvalidPostal")}</span> }
      { errorText === "invalidEmail" &&
      <span>{t("vInvalidEmail")}</span> }
      { errorText === "invalidCard" &&
      <span>{t("vInvalidCard")}</span> }
      
    </Grid>
  );
}

export default FormInput;
