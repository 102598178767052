import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Badge, MenuItem, Menu, Typography } from '@material-ui/core';
import { ShoppingCart, Translate } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

import useStyles from './styles';
import '../../i18n/i18n';
import {useTranslation} from 'react-i18next';

const PrimarySearchAppBar = ({ totalItems }) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const classes = useStyles();
  const location = useLocation();

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const cartLink = "/cart";
  const {i18n} = useTranslation();

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === "en"?"ch":"en");
  };

  const renderMobileMenu = (
    <Menu anchorEl={mobileMoreAnchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} id={mobileMenuId} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={isMobileMenuOpen} onClose={handleMobileMenuClose}>
      <MenuItem>
        <IconButton component={Link} to={cartLink} aria-label="Show cart items" color="inherit">
          <Badge badgeContent={totalItems} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
        <p>Cart</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar>
          { location.pathname.includes('adminOrders') &&
            <div className={classes.logo} />
          }
          { !location.pathname.includes('adminOrders') &&
            <Typography component={Link} to="/" className={classes.logo} />
          }
          <div className={classes.grow} />
          { !location.pathname.includes('adminOrders') &&
          <div className={classes.button}>
            <IconButton component={Link} to={cartLink} aria-label="Show cart items" color="inherit">
              <Badge badgeContent={totalItems} color="secondary">
                <ShoppingCart style={{fill: "#515151"}} />
              </Badge>
            </IconButton>
            <IconButton onClick={toggleLanguage} aria-label="Show cart items" color="inherit">
                <Translate style={{fill: "#515151"}} />
            </IconButton>
          </div> }
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};

export default PrimarySearchAppBar;
