import React from 'react';
import { Checkbox, Button, Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';

import FormInput from './CustomTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStyles from './Checkout/styles';
import {useTranslation} from 'react-i18next';

const CustomerForm = ({ next, customerData }) => {
  const {t} = useTranslation();
  const methods = useForm({
    defaultValues: {
      senderName: customerData.senderName?customerData.senderName.slice():"",
      senderContact: customerData.senderContact?customerData.senderContact.slice():"",
      recipientName: customerData.recipientName?customerData.recipientName.slice():"",
      recipientContact: customerData.recipientContact?customerData.recipientContact.slice():"",
      email: customerData.email?customerData.email.slice():""
    }
  })


  const classes = useStyles();

  const PinkCheckbox = withStyles({
    root: {
      color: pink[400],
      '&$checked': {
        color: pink[100],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const [state, setState] = React.useState({
    checkedSame: customerData.checkedSame,
    checkedEmail: customerData.checkedEmail
  });

  const checkSameData = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const data = (d) => {
    // alert(JSON.stringify(d));
    next({...d, checkedSame:state.checkedSame, checkedEmail:state.checkedEmail});
  }

  return (
    <>
      <div className={classes.stepTitle}>{t("urInfo")}</div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(data)}>
          <Grid container spacing={3}>
            <FormInput required name="senderName" label={t("cSender")} errorText={methods.errors.senderName?.type} />
            <FormInput required contactType name="senderContact" label={t("cSenderNo")} errorText={methods.errors.senderContact?.type} />
            <div className={classes.checkbox}>
              <FormControlLabel
                control={<PinkCheckbox checked={state.checkedSame} onChange={checkSameData} name="checkedSame" />}
                label={t("cSameCustomer")}
              />
            </div>
            { !state.checkedSame &&
              <FormInput required name="recipientName" label={t("cRecipient")} errorText={methods.errors.recipientName?.type} /> }
            { !state.checkedSame &&
              <FormInput required contactType name="recipientContact" label={t("cRecipientNo")} errorText={methods.errors.recipientContact?.type} /> }
            <div className={classes.checkbox2}>
              <FormControlLabel
                control={<PinkCheckbox checked={state.checkedEmail} onChange={checkSameData} name="checkedEmail" />}
                label={t("cEmail")}
              />
            </div>
            { state.checkedEmail &&
              <FormInput required emailType name="email" label={t("btnEmail")} errorText={methods.errors.email?.type} /> }
            </Grid>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button component={Link} variant="outlined" to="/cart">{t("btnBackToCart")}</Button>
            <Button type="submit" variant="contained" style={{
        backgroundColor: "#2ACB96",
        color: 'white'
    }}>{t("btnNext")}</Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default CustomerForm;
