import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { CssBaseline, Paper, Button, TextField } from '@material-ui/core';

import useStyles from './styles';
import { API_URL, DELIVERY_METHOD, PICKUP_METHOD, PICKUP_BALESTIER, PICKUP_CHINATOWN, PREFERRED_NONE, PREFERRED_MORNING, PREFERRED_AFTERNOON } from '../../commons'

const Order = ({ unsetToken, token, statusList}) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    orderData: {},
    chosenDate: '',
    emailInvoice: '',
    emailReceipt: '',
    hasChanges: false
  });

  const { id } = useParams();
  
  const fetchOrder = async () => {
    console.log('token');
    console.log(token);
    fetch(API_URL+"/orderDetails/"+id, {
      "method": "GET",
      "headers": {"accesstoken": token},
    })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        unsetToken();
        return;
      }
      if (response.status === 404) {
        setState({ ...state, orderData: null });
        return;
      }
      console.log('set orders');
      console.log(response);
      var chosenDate = new Date(response.deliveryDate).toUTCString()+"-0800";
      chosenDate = new Date(chosenDate).toISOString().split('T')[0]
      setState({ ...state, chosenDate: chosenDate, orderData: response });
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchOrder();
  }, []);  

  const handleCustomerChange = (event) => {
    console.log(event.target.value);
    let orderData = state.orderData;
    switch(event.target.name) {
      case 'customerName':
        orderData.customerName = event.target.value;
        break;
      
      case 'customerContact':
        orderData.customerContact = event.target.value;
        break;

      case 'recipientName':
        orderData.recipientName = event.target.value;
        break;
      
      case 'recipientContact':
        orderData.recipientContact = event.target.value;
        break;

      case 'postal':
        orderData.postal = event.target.value;
        break;

      case 'shop':
        orderData.shop = event.target.value;
        break;

      case 'address':
        orderData.address = event.target.value;
        break;

      case 'cardMessage':
        orderData.cardMessage = event.target.value;
        break;

      case 'remarks':
        orderData.remarks = event.target.value;
        break;

      case 'orderItems':
        orderData.orderItems = event.target.value;
        break;
      
      case 'deliveryCost':
        orderData.deliveryCost = Number(event.target.value);
        break;

      case 'scheduleId':
        orderData.scheduleId = event.target.value;
        break;

      case 'positionId':
        orderData.positionId = event.target.value;
        break;
  
      default:
        break;
    }
    
    setState({ ...state, orderData: orderData, hasChanges:true });
  }

  const handleEmailChange = (event) => {
    console.log(event.target.value);
    switch(event.target.name) {
      case 'emailInvoice':
        setState({ ...state, emailInvoice: event.target.value });
        break;

      case 'emailReceipt':
        setState({ ...state, emailReceipt: event.target.value });
        break;

      default:
        break;
    }
    
  }
  // handles when user changes input in date inputfield
  const handleChangeDate = (event) => {
    console.log("change date");
    console.log(event.target.value);
    setState({ ...state, chosenDate: event.target.value, hasChanges:true });
  };

  const handleChangeSelect = (event) => {
    console.log(event.target.name);
    let orderData = state.orderData;
    switch(event.target.name) {
      case 'preferredSession':
        orderData.preferredSession = event.target.value;
        break;
      
      case 'collectionMethod':
        orderData.collectionMethod = event.target.value;
        break;

      case 'pickupLocation':
        orderData.pickupLocation = event.target.value;
        break;

      case 'status':
        orderData.status = Number(event.target.value);
        break;
      
      default:
        break;
    }
    
    setState({ ...state, orderData: orderData, hasChanges:true });
  };

  const handleSubmit = (event) => {
    console.log('saved');
    console.log(state.orderData);
    console.log(state.chosenDate);
    let newOrderData = state.orderData;
    newOrderData.deliveryDate = state.chosenDate;
    
    fetch(API_URL+"/updateOrder/"+state.orderData.orderID, {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "accesstoken": token
      },
      "body": JSON.stringify(newOrderData)
    })
    .then(response => response.json())
    .then(response => {
      alert('Updated Successfully');
      console.log(response);
    })
    .catch(err => {
      console.log(err);
    });

    // alert('A name was submitted: ' + this.state.value);
    // event.preventDefault();
    setState({ ...state, hasChanges:false });
  }

  const handleEmailOrder = (event) => {
    console.log('Sending email');

    fetch(API_URL+"/emailOrder", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "accesstoken": token
      },
      "body": JSON.stringify({id:state.orderData.orderID, altEmail: state.emailInvoice})
    })
    .then(response => response.json())
    .then(response => {
      alert('Emailed Successfully');
      console.log(response);
    })
    .catch(err => {
      console.log(err);
    });
  }

  const handleEmailReceipt = (event) => {
    console.log('Sending email');

    fetch(API_URL+"/emailOrderReceipt", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "accesstoken": token
      },
      "body": JSON.stringify({id:state.orderData.orderID, altEmail: state.emailReceipt})
    })
    .then(response => response.json())
    .then(response => {
      alert('Emailed Successfully');
      console.log(response);
    })
    .catch(err => {
      console.log(err);
    });
  }

  var updated, created, pickupLocation, deliveryAddress, statusSelection;
  if (state.orderData) {
    updated = new Date(state.orderData.updated);
    created = new Date(state.orderData.created);
    const orderItems = state.orderData.orderItems?JSON.parse(state.orderData.orderItems):null;
    var subtotal = orderItems?orderItems.subtotal:0;
    var orderItemsHtml = [];

    if (orderItems) {
      for (var i=0; i<orderItems.line_items.length; i++) {
        let alacarte = orderItems.line_items[i].categoryID === 1 ? '[Ala-Carte] ':'';
        let config = orderItems.line_items[i].config?<br />:'';
        let config2 = orderItems.line_items[i].config?orderItems.line_items[i].config:'';
        let promotionTitle = orderItems.line_items[i].promotionTitle?<br />:'';
        let promotionTitle2 = orderItems.line_items[i].promotionTitle?orderItems.line_items[i].promotionTitle:'';
        let orderItemHtml = <tr key={i}><td className={classes.tableContent}>{alacarte}{ orderItems.line_items[i].name }{config}{config2}{promotionTitle}{promotionTitle2} </td><td className={classes.tableContent}>{orderItems.line_items[i].quantity}</td><td className={classes.tableContent}>${parseFloat(orderItems.line_items[i].priceFormatted).toFixed(2)}</td><td className={classes.tableContent}>${orderItems.line_items[i].line_total.toFixed(2)}</td></tr>;
        orderItemsHtml.push(orderItemHtml);
      }
    }

    pickupLocation = <span>Pickup Location: {state.orderData.pickupLocation}
    <select name="pickupLocation" onChange={handleChangeSelect} defaultValue={state.orderData.pickupLocation === PICKUP_BALESTIER}>
      <option value={PICKUP_BALESTIER}>{PICKUP_BALESTIER}</option>
      <option value={PICKUP_CHINATOWN}>{PICKUP_CHINATOWN}</option>
    </select></span>;

    deliveryAddress = <span>Postal: <input type="text" name="postal" value={state.orderData.postal} onChange={handleCustomerChange} /> <br />
    Shop/Building: <input type="text" name="shop" value={state.orderData.shop} onChange={handleCustomerChange} /> <br />
    Address: <input type="text" name="address" value={state.orderData.address} onChange={handleCustomerChange} /></span>
  }

  var statusArray = [];
  if (statusList.length > 0) {
    for (var j = 0; j < statusList.length; j++) {
      let anOption = <option key={j} value={statusList[j].id}>{statusList[j].name}</option>;
      statusArray.push(anOption);
    }
  }

  statusSelection = <select name="status" onChange={handleChangeSelect} value={state.orderData.status}>{statusArray}</select>;

  if (!state.orderData) {
    return (
      <>
        <CssBaseline />
        <div className={classes.toolbar} />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            No data found for this order No
          </Paper>
      </main>
    </>
  );
  }
  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
          <h1 className="pink">Sales Invoice</h1>
          <div className={classes.lineHeight}>
          Order Ref No: {state.orderData.orderID}<br /> 
          Customer: <input type="text" name="customerName" value={state.orderData.customerName?state.orderData.customerName:''} onChange={handleCustomerChange} /><br />
          Customer Contact: <input type="text" name="customerContact" value={state.orderData.customerContact?state.orderData.customerContact:''} onChange={handleCustomerChange} /><br />
          Recipient: <input type="text" name="recipientName" value={state.orderData.recipientName?state.orderData.recipientName:''} onChange={handleCustomerChange} /><br />
          Recipient Contact: <input type="text" name="recipientContact" value={state.orderData.recipientContact?state.orderData.recipientContact:''} onChange={handleCustomerChange} /><br />
          <TextField
                label="Date"
                type="date"
                value={state.chosenDate}
                onChange={handleChangeDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              /><br />
          Preferred Session: 
          <select name="preferredSession" onChange={handleChangeSelect} value={state.orderData.preferredSession}>
            <option value={PREFERRED_NONE}>{PREFERRED_NONE}</option>
            <option value={PREFERRED_MORNING}>{PREFERRED_MORNING}</option>
            <option value={PREFERRED_AFTERNOON}>{PREFERRED_AFTERNOON}</option>
          </select><br />
          Collection Method: 
          <select name="collectionMethod" onChange={handleChangeSelect} value={state.orderData.collectionMethod}>
            <option value={DELIVERY_METHOD}>{DELIVERY_METHOD}</option>
            <option value={PICKUP_METHOD}>{PICKUP_METHOD}</option>
          </select><br />
          { state.orderData.collectionMethod === PICKUP_METHOD &&
            pickupLocation
          }
          { state.orderData.collectionMethod === DELIVERY_METHOD &&
            deliveryAddress
          }
          <br />Card Message: <input type="text" name="cardMessage" value={state.orderData.cardMessage?state.orderData.cardMessage:''} onChange={handleCustomerChange} /><br />
          Remarks: <input type="text" name="remarks" value={state.orderData.remarks?state.orderData.remarks:''} onChange={handleCustomerChange} /><br />
          Delivery Cost: <input type="text" name="deliveryCost" value={state.orderData.deliveryCost?state.orderData.deliveryCost:''} onChange={handleCustomerChange} /><br />

          Status: {statusSelection}<br />
          {state.orderData.status === 3 &&
          <span>Schedule Id: <input type="text" name="scheduleId" value={state.orderData.scheduleId?state.orderData.scheduleId:''} onChange={handleCustomerChange} /><br /></span> } 
          {state.orderData.status === 3 &&
          <span>Position Id: <input type="text" name="positionId" value={state.orderData.positionId?state.orderData.positionId:''} onChange={handleCustomerChange} /><br /></span> } 
          Feedback: {state.orderData.feedback} <br />
          Updated: {updated.toLocaleString()} <br />
          Created: {created.toLocaleString()} <br />
          </div>
          <br />
          <hr />
          <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.tableContent}>Item</th>
              <th className={classes.tableContent}>Qty</th>
              <th className={classes.tableContent}>Price</th>
              <th className={classes.tableContent}>Total</th>
            </tr>
          </thead>
          <tbody>
            {orderItemsHtml}
            <tr>
              <td className={classes.tableContent} colSpan="2"> </td>
              <td className={classes.tableContent}>Subtotal</td>
              <td className={classes.tableContent}>${subtotal.toFixed(2)}</td>
            </tr>
            <tr>
              <td className={classes.tableContent} colSpan="2"> </td>
              <td className={classes.tableContent}>Delivery</td>
              <td className={classes.tableContent}>${parseFloat(state.orderData.deliveryCost).toFixed(2)}</td>
            </tr>
            <tr>
              <td className={classes.tableContent} colSpan="2"> </td>
              <td className={classes.tableContent}><b>Total</b></td>
              <td className={classes.tableContent}><h1>${(subtotal+state.orderData.deliveryCost).toFixed(2)}</h1></td>
            </tr>
          </tbody>
          </table>

          <textarea name="orderItems" rows="6" cols="50" value={state.orderData.orderItems} onChange={handleCustomerChange} />
          <br />
            <br />
            <Button onClick={handleSubmit} disabled={!state.hasChanges}>
              Save Order
            </Button><br />
            <input type="text" name="emailInvoice" placeholder={'Enter email'} onChange={handleEmailChange} />
            <Button onClick={handleEmailOrder} disabled={state.hasChanges}>
              Email Invoice
            </Button><br />
            <input type="text" name="emailReceipt" placeholder={'Enter email'} onChange={handleEmailChange} />
            <Button onClick={handleEmailReceipt} disabled={state.hasChanges || state.orderData.status < 3}>
              Email Receipt
            </Button>
          </form>
        </Paper>
      </main>
    </>
  );
};

export default Order;
