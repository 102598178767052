import React, { useState } from 'react';
import { Typography, Card, Button, CardContent, CardActions, IconButton, Checkbox } from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import useStyles from './styles';
import mobiscroll from '@mobiscroll/react-lite';
import '@mobiscroll/react-lite/dist/css/mobiscroll.min.css';
import { Alert } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Carousel from 'react-bootstrap/Carousel'
import '../../../i18n/i18n';
import {useTranslation} from 'react-i18next';
import Radium, {StyleRoot} from 'radium';
import { zoomOutDown, rubberBand } from 'react-animations';


// const rubberFade = merge(rubberBand, fadeOutDown);

const styles = {
  packageDown: {
    animation: 'x 1s',
    animationName: Radium.keyframes(zoomOutDown, 'packageDown')
  },
  rubberBand: {
    animation: 'x 1s',
    animationName: Radium.keyframes(rubberBand, 'rubberBand')
  }
}

const Product = ({ categoryId, product, onAddToCart, onUpdateSelection }) => {
  const {t, i18n } = useTranslation();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [qty, setQty] = useState(1);
  const [prdImg, setPrdImg] = useState(styles.dud);
  const [cartIcon, setCartIcon] = useState(styles.dud);
  const [mixMatchArray] = useState([0, 0, 0, 0, 0]);
  const [radioSelection, setRadioSelection] = useState(1);
  
  const handleAddToCart = () => { 
    if(product.stepperMode) {
      var sum = mixMatchArray.reduce(function(a, b) { return a + b; }, 0);
      console.log(mixMatchArray);
      console.log("sum: "+sum);
      if (sum !== 7) {
        setError(true);
        return;
      } else {
        setError(false);
        onAddToCart(categoryId, product.productID, qty, mixMatchArray);
        return;
      }
    }
    setPrdImg(styles.packageDown);
    setTimeout(function() {
      setPrdImg(styles.dud);
      setQty(1);
    }, 1000);
    setTimeout(function() {
      setCartIcon(styles.rubberBand);
    }, 800);
    setTimeout(function() {
      setCartIcon(styles.dud);
    }, 1800);
    onAddToCart(categoryId, product.productID, qty, null, state.checkedOption, product.radioMode?radioSelection:null)
  } ;
  const handleUpdateSelection = (event) => onUpdateSelection(categoryId, product.productID, event.target.value);
  
  const handleQtyMinus = () => { 
    console.log('handleQtyMinus');
    if (qty > 1) {
      setQty(qty-1);
    }
  };

  const handleQtyAdd = () => { 
    console.log('handleQtyAdd');
    setQty(qty+1);
  };

  const PinkCheckbox = withStyles({
    root: {
      color: pink[400],
      '&$checked': {
        color: pink[100],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const [state, setState] = React.useState({
    checkedOption: false
  });

  const checkOptions = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const stepperHandler1 = (val) => {
    mixMatchArray[0] = val;
  };

  const stepperHandler2 = (val) => {
    mixMatchArray[1] = val;
  };

  const stepperHandler3 = (val) => {
    mixMatchArray[2] = val;
  };

  const stepperHandler4 = (val) => {
    mixMatchArray[3] = val;
  };

  const stepperHandler5 = (val) => {
    mixMatchArray[4] = val;
  };

  const myRadioChanged = (event) => {
    console.log(event.target.value);
    setRadioSelection(parseInt(event.target.value));
  }

  var selectionMode;
  var parsedSelectionMode;

  if(product.selectionMode) {
    parsedSelectionMode = JSON.parse(product.selectionMode);
    selectionMode = parsedSelectionMode.map((select, index) =>
      <mobiscroll.Segmented key={index} name="selection" value={index} checked={select.label === parsedSelectionMode[product.selected].label} onChange={handleUpdateSelection} >
      {i18n.language === "ch"?select.cLabel:select.label}
    </mobiscroll.Segmented>
    );
  }

  var radioMode;
  var parsedRadioMode;

  if(product.radioMode) {
    parsedRadioMode = JSON.parse(product.radioMode);
    radioMode = parsedRadioMode.map((radio, index) =>
      <mobiscroll.Radio name={"group"+product.productID} key={index} value={radio.id} checked={radioSelection === radio.id} onChange={myRadioChanged}>
          { i18n.language === "ch"?radio.cName:radio.name }
      </mobiscroll.Radio>
    );
  }

  var stepperMode;

  if(product.stepperMode) {
    var activeHandler;
    stepperMode = JSON.parse(product.stepperMode).map((sub, index) => {
      switch(index+1) {
        case 1:
          activeHandler = stepperHandler1;
          break;
        case 2:
          activeHandler = stepperHandler2;
          break;
        case 3:
          activeHandler = stepperHandler3;
          break;
        case 4:
          activeHandler = stepperHandler4;
          break;
        case 5:
          activeHandler = stepperHandler5;
          break;
        default:
          activeHandler = stepperHandler1;
          break;
      }
      return (<mobiscroll.Slider key={index} value={0} onChange={activeHandler} min={0} max={7} step={1} data-tooltip="true" data-step-labels="[0, 1, 2, 3, 4, 5, 6, 7]">{i18n.language === "ch"?sub.cLabel:sub.label}</mobiscroll.Slider>)
  });
  }


  const price = (product.selectionMode) ? parsedSelectionMode[product.selected].priceFormatted:product.priceFormatted;
  const unit = (product.selectionMode) ? i18n.language === "ch"?parsedSelectionMode[product.selected].cPriceUnit:parsedSelectionMode[product.selected].priceUnit:i18n.language === "ch"?product.cPriceUnit:product.priceUnit;
  var mediaList = (product.selectionMode) ? parsedSelectionMode[product.selected].media:product.media;
  var carouselItems;
  if (mediaList.startsWith("[")) {
    mediaList = JSON.parse(mediaList);
  } else {
    mediaList = new Array(mediaList);
  }

  carouselItems = mediaList.map((productImg, index) =>
    <Carousel.Item key={productImg}>
      <img
        className={classes.mediaCarousel}
        src={productImg}
      />
    </Carousel.Item>
  );

  const description = (product.selectionMode) ? i18n.language === "ch"?parsedSelectionMode[product.selected].cDescription:parsedSelectionMode[product.selected].description:i18n.language === "ch"?product.cDescription:product.description;
  const name = (product.selectionMode) ? i18n.language=="ch"?parsedSelectionMode[product.selected].cName:parsedSelectionMode[product.selected].name:i18n.language === "ch"?product.cName:product.name;
  
  //<CardMedia className={classes.media} image={mediaList[0]} title={name} />
      
  const onCompleteCallBack = () => {
    console.log('onCompleteCallBack');
  }
  return (
    <Card className={classes.root}>
      <StyleRoot>
      <div style={prdImg}>
      <Carousel fade indicators={false} interval={2000} controls={false}>
        { carouselItems }
      </Carousel>
      </div>
    </StyleRoot>

      <CardContent>
        <div className={classes.cardContent}>
          <div className={classes.productTitle}>
            {name}
          </div>
        </div>
        <div className={classes.productPrice}>
            S${price +" "+ unit}
          </div>
          <div className={classes.productPromo}>
            {i18n.language === "ch"?product.cMsg:product.msg }
          </div>
          { product.stepperMode && (
          <mobiscroll.Form theme="mobiscroll" themeVariant="light">
          <mobiscroll.FormGroup className={classes.customSegment}>
              { stepperMode }
          </mobiscroll.FormGroup>
          </mobiscroll.Form>
          )}
          <div className={classes.productDesc} dangerouslySetInnerHTML={{ __html: description }} />
          
          { product.checkboxMsg &&
          <div className={classes.checkbox}>
              <FormControlLabel
                control={<PinkCheckbox checked={state.checkedOption} onChange={checkOptions} name="checkedOption" />}
                label={i18n.language === "ch"?product.cCheckboxMsg:product.checkboxMsg}
              />
            </div>
          }
          { product.selectionMode && (
          <mobiscroll.Form theme="windows" themeVariant="light">
                <mobiscroll.FormGroup> 
                { selectionMode }
                </mobiscroll.FormGroup>
                </mobiscroll.Form>
          )}
          { radioMode }
      </CardContent>
      {error && (
        <Alert onClose={() => { setError(false); }} severity="error">
        {t("minSeven")}<span> 😊</span>
      </Alert>
      )} 
      <CardActions disableSpacing className={classes.cardActions}>
        <div className={classes.buttons}>
          {t("qty")}: 
          <Button type="button" size="small" onClick={handleQtyMinus}>-</Button>
          <Typography>&nbsp;{qty}&nbsp;</Typography>
          <Button type="button" size="small" onClick={handleQtyAdd}>+</Button>
        </div>
        <IconButton aria-label="Add to Cart" color="secondary" onClick={handleAddToCart}>
        <StyleRoot>
          <div style={cartIcon}>
            <AddShoppingCart style={{fill: "#FF3399"}} />
          </div>
        </StyleRoot>
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default Product;

