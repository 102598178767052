import React from 'react';

import ProductCategory from './Product/ProductCategory';
import useStyles from './styles';
import Carousel from 'react-bootstrap/Carousel'
import {
  ScrollingProvider,
  useScrollSections,
  Section,
} from 'react-scroll-section';

  const Products = ({ products, onAddToCart, onUpdateSelection }) => {
  const classes = useStyles();

  const onHandleCategoryChange = (value) => { 
    console.log('onHandleCategoryChange');
    sections[value].onClick();
  };

  var sections;

  const Spacer = () => {
    sections = useScrollSections();

    return (
      <div className={classes.spacer}></div>
    );
  };

  if (!products.length) return <p>Loading...</p>;

  return (
    <main className={classes.content}>
    <ScrollingProvider offset={-80}>
      <div className={classes.toolbar} />
        <Carousel fade indicators={false} interval={1000} controls={false}>
        <Carousel.Item key={1}>
          <div className={classes.carousel}>
            <div className={classes.bannerImg}></div>
          </div>
        </Carousel.Item>
        {/* <Carousel.Item key={1}>
         <img src="/img/Banner2022A.png" resizeMode="center" className={classes.banner} />
         
          <img src={"/img/banner1.jpeg"} />
          <Carousel.Caption>
          <img width="50%" src={"/img/banner2.jpeg"} />
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item key={2}>
          <img src={"/img/banner2.jpeg"} />
        </Carousel.Item> */}
      </Carousel>
        {products.map((category) => (
            <Section id={"cat"+category.id} key={category.id}><ProductCategory key={category.id} category={category} onAddToCart={onAddToCart} onUpdateSelection={onUpdateSelection} products={products} onHandleCategoryChange={onHandleCategoryChange} /></Section>
        ))}
        <Spacer />
        </ScrollingProvider>
    </main>
  );
};

export default Products;