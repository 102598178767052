import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './Checkout/styles';
import {useTranslation} from 'react-i18next';

const SubmittedForm = ({ orderNo }) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <>
          <div>
            <h3>{t("cOrderNo")}: {orderNo}</h3>
            <p>{t("cOrder10q")}</p>
            <p>{t("cOrderSoon")}</p>
           </div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button component={Link} variant="outlined" to="/cart">{t("btnBackToCart")}</Button>
          </div>
    </>
  );
};

export default SubmittedForm;
