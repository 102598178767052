import React, { useState } from 'react';
import { Card, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Product from './Product';

import useStyles from './styles';
import '../../../i18n/i18n';
import {useTranslation} from 'react-i18next';

const ProductCategory = ({ category, onAddToCart, onUpdateSelection, products, onHandleCategoryChange}) => {
  const classes = useStyles();
  const {t, i18n } = useTranslation();
  const [anchor, setAnchor] = useState(-1);

  const selectionMode = products.map((select, index) =>
    <MenuItem key={index} value={index}>{i18n.language === "ch"?select.cCategory:select.category}</MenuItem>
  );

  const handleCategoryChange = (event) => { 
    console.log('handleCategoryChange');
    setAnchor(event.target.value);
    onHandleCategoryChange(event.target.value);
  };
  
  return (
    <Card className={classes.root}>
    <div className={category.id%2 === 0?classes.cat1:classes.cat2}>
      <div className={classes.categorySection}>
        <div className={classes.categorySpace}>
          <div className={classes.categoryTitle}>
            {i18n.language === "ch"?category.cCategory:category.category}
          </div>
          <div className={classes.categoryDesc}>
            {i18n.language === "ch"?category.cDescription:category.description}
          </div>
        </div>
        <div className={classes.categorySearch}>
            <FormControl fullWidth>
              <InputLabel id={"demo-simple-select-label"+category.id}>{t("lookinFor")}</InputLabel>
              <Select
                labelId={"demo-simple-select-label"+category.id}
                id={"demo-simple-select"+category.id}
                value={anchor}
                label={t("lookinFor")}
                onChange={handleCategoryChange}
              >
                { selectionMode }
              </Select>
            </FormControl>
        </div>
      </div>
      <Grid container spacing={4}>
        {category.products.map((product) => (
          <Grid key={product.id} item xs={12} sm={6} md={4} lg={3}>
            <Product key={product.id} categoryId={category.id} product={product} onAddToCart={onAddToCart} onUpdateSelection={onUpdateSelection} />
          </Grid>
        ))}
      </Grid>
      </div>
    </Card>
  );
};

export default ProductCategory;

