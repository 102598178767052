import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  title: {
    marginTop: '5%',
    fontFamily: 'Oswald',
    color: '#FF3399'
  },
  emptyButton: {
    minWidth: '150px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px',
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: '20px',
    },
  },
  checkoutButton: {
    minWidth: '150px'
  },
  link: {
    textDecoration: 'none',
    color: '#FF3399',
    ":hover": '#FF3399'
  },
  cardDetails: {
    display: 'flex',
    marginTop: '10%',
    width: '100%',
    justifyContent: 'space-between',
  },
  subTot: {
    fontFamily: 'Oswald',
  },
  cartContainer: {
    margin: 15
  },
  spacer: {
    paddingBottom: 50,
  },
  payment: {
    marginRight: 15,
    marginTop: 15,
  },
}));
