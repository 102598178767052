import React from 'react';
import { Checkbox, Button } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import InputEmoji from "react-input-emoji";

import { withStyles } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';

import FormInput from './CustomTextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useStyles from './Checkout/styles';
import {useTranslation} from 'react-i18next';

var emojiText = "";
const FeedbackForm = ({ next, feedbackData, backStep}) => {
  const {t} = useTranslation();
  const methods = useForm({
    defaultValues: {
      feedback: feedbackData.feedback?feedbackData.feedback.slice():"",
      knowUs: feedbackData.knowUs?feedbackData.knowUs.slice():"",
      cardMessage: feedbackData.cardMessage?feedbackData.cardMessage.slice():""
    }
  })

  const classes = useStyles();

  const PinkCheckbox = withStyles({
    root: {
      color: pink[400],
      '&$checked': {
        color: pink[100],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const [state, setState] = React.useState({
    checkedFB: false,
    checkedIG: false,
    checkedMouth: false,
  });

  const checkSameData = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const data = (d) => {
    // alert(JSON.stringify({...d, checkedFB:state.checkedFB, checkedIG:state.checkedIG, checkedMouth:state.checkedMouth}));
    if (state.checkedFB || state.checkedIG || state.checkedMouth || d.knowUs.trim() != "") {
      next({...d, checkedFB:state.checkedFB, checkedIG:state.checkedIG, checkedMouth:state.checkedMouth, cardMessage:emojiText});
    } else {
      alert("Please let us know how you got to know about Happy Doppi, thank you!");
    }
  }

  function handleOnEnter(text) {
    console.log("enter", text);
  }

  const emojiOnChange = (text) => {
    // console.log(text);
    // setState({ ...state, emojiText: text });
    emojiText = text;
  };

   /*<FormInput cardType name="cardMessage" label="Card Message" errorText={methods.errors.cardMessage?.type} />*/

  return (
    <>
      <div className={classes.stepTitle}>{t("cAlmost")}</div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(data)}>
          <div>
          
            <p className={classes.headerText}>{t("cGift")}</p>
            <p className={classes.subText}>{t("cGiftPersonalise")}</p>
            <InputEmoji
              value={emojiText}
              onChange={emojiOnChange}
              cleanOnEnter
              onEnter={handleOnEnter}
              placeholder={t("cGiftMsg")}
            />

            <br />
            <p className={classes.headerText}>{t("cFind")}</p>
              
              <div className={classes.checkbox}>
              <FormControlLabel
                control={<PinkCheckbox checked={state.checkedFB} onChange={checkSameData} name="checkedFB" />}
                label={t("cFB")}
              />
              <FormControlLabel
                control={<PinkCheckbox checked={state.checkedIG} onChange={checkSameData} name="checkedIG" />}
                label={t("cIG")}
              />
              <FormControlLabel
                control={<PinkCheckbox checked={state.checkedMouth} onChange={checkSameData} name="checkedMouth" />}
                label={t("cMouth")}
              />
            </div>
            <FormInput name="knowUs" label={t("cOthers")} />
            <br />
            <p className={classes.headerText}>{t("cImprove")}</p>
            <FormInput name="feedback" label={t("feedback")} />
            </div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button component={Link} variant="outlined" to="/cart">{t("btnBackToCart")}</Button>
            <div>
            <Button type="button" variant="contained" style={{
        backgroundColor: "#509AF9",
        color: 'white',
        marginRight: 10
    }} onClick={backStep}>{t("btnBack")}</Button>
            <Button style={{ marginLeft: 10 }} type="submit" variant="contained" style={{
        backgroundColor: "#2ACB96",
        color: 'white'
    }}>{t("btnSubmit")}</Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FeedbackForm;
