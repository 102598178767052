import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    // maxWidth: 345, original width style
    maxWidth: '100%',
    padding: 10
  },
  mediaImg: {
    height: 260,
  },
  '@media (min-width: 480px)': {
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      direction: 'row'
    },
  },
  cartActions: {
    justifyContent: 'space-between',
    flex: 0.2
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  productTitle: {
    fontSize: 18,
    fontWeight: "700",
  },
  productConfig: {
    fontSize: 14,
  },
  productPromo: {
    fontSize: 14,
    color: "#FF68DB"
  },
  productDesc: {
    fontSize: 14,
    color: 'grey',
    marginTop: 5
  },
  cardActions: {
    flex: 0.2,
    paddingRight: 20
  },
  cardProducts: {
    flex: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    flexDirection: 'column',
  },
  productPrice: {
    fontSize: 16,
    flex: 0.1,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
}));
