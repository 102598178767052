import React from 'react';
import { Typography, Button, Card, CardActions, CardContent } from '@material-ui/core';

import useStyles from './styles';
import '../../../i18n/i18n';
import {useTranslation} from 'react-i18next';

const CartItem = ({ item, onUpdateCartQty, onRemoveFromCart }) => {
  const {t, i18n} = useTranslation();
  const classes = useStyles();

  const handleUpdateCartQty = (lineItemId, newQuantity) => onUpdateCartQty(lineItemId, newQuantity);

  const handleRemoveFromCart = (lineItemId) => onRemoveFromCart(lineItemId);

  var productImg = item.media;
  if (productImg.startsWith("[")) {
    productImg = JSON.parse(productImg);
    productImg = productImg[0];
  }

  return (
    <Card className="classes.root">
      <CardContent className={classes.cardContent}>
        <div className={classes.cardActions}>
            <img src={productImg} alt={item.name} width="140" />
        </div>
        <div className={classes.cardProducts}>
          <div className={classes.productTitle}>{i18n.language === "ch"?item.cName:item.name} </div>
          <div className={classes.productConfig} dangerouslySetInnerHTML={{ __html: i18n.language === "ch"?item.cConfig:item.config }} />
          
          <div className={classes.productPromo}>{i18n.language === "ch"?item.cPromotionTitle:item.promotionTitle} </div>
        </div><br />
        <CardActions className={classes.cardActions}>
          <div className={classes.buttons}>
            <Button type="button" size="small" onClick={() => handleUpdateCartQty(item.productID, item.quantity - 1)}>-</Button>
            <Typography>&nbsp;{item.quantity}&nbsp;</Typography>
            <Button type="button" size="small" onClick={() => handleUpdateCartQty(item.productID, item.quantity + 1)}>+</Button>
          </div>
          <Button variant="contained" type="button" color="secondary" onClick={() => handleRemoveFromCart(item.productID)}>{t("btnRemove")}</Button>
        </CardActions>
        <div className={classes.productPrice}>
          S${item.line_total.toFixed(2)}
        </div>
      </CardContent>
    </Card>
  );
};

export default CartItem;
