import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Paper, IconButton } from '@material-ui/core';

import useStyles from './styles';
import { API_URL } from '../../commons';

const Login = ({ setToken}) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    username: '',
    password: ''
  });

  async function handleSubmit (event) {
    console.log('saved');
    const loginJson = {username:state.username, password:state.password};
    
    fetch(API_URL+"/aLogin", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
      },
      "body": JSON.stringify(loginJson)
    })
    .then(response => response.json())
    .then(response => {
      if (response.token) {
        setToken(response);
        return;
      }
      
      alert('Login Failed');
    })
    .catch(err => {
      console.log(err);
    });

    // alert('A name was submitted: ' + this.state.value);
    // event.preventDefault();
  }

  const handleInputChange = (event) => {
    switch(event.target.name) {
      case 'username':
        setState({ ...state, username: event.target.value });
        break;
      
      case 'password':
        setState({ ...state, password: event.target.value });
        break;

      default:
        break;
    }
  }
 
  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
            <h1 className="pink">Login</h1>
            Username: <input type="text" name="username" value={state.username} onChange={handleInputChange} /><br />
            Password: <input type="password" name="password" value={state.password} onChange={handleInputChange} /><br />
          
            <IconButton aria-label="Login" onClick={handleSubmit}>
              Submit
            </IconButton>

          </form>
        </Paper>
      </main>
    </>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}

export default Login;
