import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { CssBaseline, Paper, Button } from '@material-ui/core';

import useStyles from './styles';
import { API_URL, DELIVERY_METHOD, PICKUP_METHOD } from '../../commons'

const OrderSchedules = ({ unsetToken, token }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    orderData: [],
    userRole: ''
  });

  const { id } = useParams();
  
  const fetchOrder = async () => {
    console.log('token');
    console.log(token);
    fetch(API_URL+"/orderSchedule/", {
      "method": "GET",
      "headers": {"accesstoken": token},
    })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        unsetToken();
        return;
      }
      if (response.status === 404) {
        return;
      }
      console.log('set orders');
      console.log(response);
      setState({ ...state, orderData: response.orders, userRole:response.role });
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchOrder();
  }, []);  

  const doneHandler = (orderID) => {
    fetch(API_URL+"/updateOrderStatus/"+orderID, {
      "method": "PUT",
      "headers": {"accesstoken": token},
      "body": JSON.stringify({"status": 4})
    })
    .then(response => response.json())
    .then(response => {
      console.log(response.status);
      if (response.status === 401) {
        unsetToken();
        return;
      }
      if (response.status === 404) {
        return;
      }
      console.log('remove order');
      console.log(response);

      if (response.status === 4) {
        var filtered = state.orderData.filter(function(order, index, arr){ 
            return order.orderID !== orderID;
        });
        console.log(filtered);
        setState({ ...state, orderData: filtered });
      }
    })
    .catch(err => {
      console.log(err);
    });
  }
  
  var orderList = [];
  var orderRemarks = {"ready":"", "pref":"", "remarks":""};

  for (var j=0; j<state.orderData.length; j++) {
    var order = state.orderData[j];
    var newOrder = {};
    newOrder.orderRemarks = order.remarks?JSON.parse(order.remarks):orderRemarks;
    const orderItems = order.orderItems?JSON.parse(order.orderItems):null;
    newOrder.orderItemsHtml = [];

    if (orderItems) {
      for (var i=0; i<orderItems.line_items.length; i++) {
        let alacarte = orderItems.line_items[i].categoryID === 1 ? '[Ala-Carte] ':'';
        let config = orderItems.line_items[i].config?<br />:'';
        let config2 = orderItems.line_items[i].config?orderItems.line_items[i].config:'';
        
        // form an array for mix and match
        let mixArray = config2.split('<br />');
        if (mixArray.length > 1) {
          config2 = '';
          for (var k=0; k<mixArray.length; k++) {
            config2 += '- '+mixArray[k];
            config2 += '<br />';
          }
        }

        let itemName = orderItems.line_items[i].name;
        itemName = itemName.replace(/rainbow lapis/gi, "Lapis");
        // itemA is used to contain 8 inch - red font
        let itemA = itemName.startsWith("8 inch") ? "8 inch":"";
        let itemB = itemA === "" ? itemName:itemName.substr(6);

        // let promotionTitle = orderItems.line_items[i].promotionTitle?<br />:'';
        // let promotionTitle2 = orderItems.line_items[i].promotionTitle?orderItems.line_items[i].promotionTitle:'';
        let orderItemHtml = <tr className={classes.orderRow} key={i}><td className={classes.tableContentId}>{i+1}.</td><td className={classes.tableContentItem}>{alacarte}<span style={{color: "#F00", fontWeight: "bold"}}>{ itemA }</span>{ itemB }{config}<div dangerouslySetInnerHTML={{ __html: config2 }} /> </td><td className={classes.tableContentQty} style={{color: orderItems.line_items[i].quantity > 1 ? "#F00":"black", fontWeight: orderItems.line_items[i].quantity > 1 ? "bold":"normal", fontSize: orderItems.line_items[i].quantity > 1 ? 20:14}}>{orderItems.line_items[i].quantity}</td></tr>;
        newOrder.orderItemsHtml.push(orderItemHtml);
      }
    }
    console.log(newOrder);
    orderList.push(newOrder);
  }
  
  var today = new Date();
  today.setHours(8,0,0,0);
  // new Date("Fri Jan 20 2012 11:51:36 GMT-0500").toUTCString()
  // Tue, 28 Dec 2021 16:00:00 GMT
  const dateOptions = { month: 'short', day: 'numeric', weekday: 'short' };

  const schedule = state.orderData.map((order, index) =>
  <div key={index} className={classes.scheduleGroup}>
    
    <div className={classes.scheduleDate}>
    { (order.positionId === 0 || today.getTime() === new Date(order.deliveryDate).getTime()) && <h3>{new Date(new Date(order.deliveryDate).toUTCString()+"-0800").toLocaleDateString('en-US', dateOptions)}</h3>}
    { state.userRole === 'admin' && 
      <Button onClick={() => doneHandler(order.orderID)} style={{ marginLeft: 10 }} variant="contained" style={{
        backgroundColor: "#2ACB96",
        color: 'white'
    } }>Done</Button> }
    </div>
    <div className={classes.scheduleSection}>
    <div className={classes.lineHeight}>
    {order.collectionMethod === DELIVERY_METHOD &&
      <h4 className={classes.scheduleDelivery}>{order.scheduleId}. {order.collectionMethod} (Ready by {orderList[index].orderRemarks.ready}) {orderList[index].orderRemarks.pref? "- "+ orderList[index].orderRemarks.pref:""}</h4>
    }
    {order.collectionMethod === PICKUP_METHOD &&
      <h4 className={classes.schedulePickup}>{order.scheduleId}. {order.collectionMethod} at {order.pickupLocation} ({orderList[index].orderRemarks.ready})</h4>
    }
    <div className={classes.scheduleWrap}>Order Ref No: {order.orderID}</div>
    <div className={classes.scheduleCustomer}>{order.recipientName?order.recipientName:order.customerName} @ {order.recipientContact?order.recipientContact:order.customerContact} [{order.customerName} @ {order.customerContact}] {order.preferredSession} </div>
    { order.collectionMethod === DELIVERY_METHOD &&
      <div className={classes.scheduleWrap}>{order.address  + " Singapore " + order.postal + (order.shop?" "+order.shop:"")}</div>
    }
    <div className={classes.scheduleMsg}>{order.cardMessage && order.cardMessage}</div>
    <div className={classes.scheduleRemarks}>{orderList[index].orderRemarks.remarks}</div>

    </div>
    <hr />
    <table className={classes.table}>
    <thead>
      <tr>
        <th className={classes.tableContentId}>No</th>
        <th className={classes.tableContentItem}>Item</th>
        <th className={classes.tableContentQty}>Qty</th>
      </tr>
    </thead>
    <tbody className={classes.scheduleBottomMargin}>
      {orderList[index].orderItemsHtml}
    </tbody>
    </table>
    <div className={classes.spacer}></div>
    </div>
  </div>
  
  );

  if (!state.orderData) {
    return (
      <>
        <CssBaseline />
        <div className={classes.toolbar} />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            No orders found
          </Paper>
      </main>
    </>
  );
  }
  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <h1 className="pink">Happy Doppi Schedules</h1><br /><br />
          { schedule }
        </Paper>
      </main>
    </>
  );
};

export default OrderSchedules;
