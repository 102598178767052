import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  toolbar: theme.mixins.toolbar,
  layout: {
    marginTop: '5%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  '@media (max-width: 480px)': {
    stepper: {
      display: 'flex',
      padding: theme.spacing(2, 0, 5),
      direction: 'row',
    },
  },
  stepperLabel: {
    flex: 0.1
  },
  stepperCircle: {
    "& .MuiStepIcon-active": { color: "ff99cc", backgroundColor: 'green' },
    "& .MuiStepIcon-completed": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
  },
  title: {
    fontFamily: 'Oswald',
    color: '#FF3399'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  divider: {
    margin: '20px 0',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    margin: '0px 10px',
    width: '100%'
  },
  checkbox2: {
    margin: '30px 10px',
    width: '100%'
  },
  dateContainer: {
    margin: '12px 10px',
  },
  pinkNotes: {
    color: '#ec407a',
    marginLeft: '12px'
  },
  stepTitle: {
    fontSize: 20,
    marginTop: 10,
    marginBottom: 20,
    fontFamily: 'Oswald',
    color: '#FF3399'
  },
  announcement: {
    fontSize: 18,
    marginTop: 10,
    marginBottom: 20,
    color: '#FF3399'
  },
  imptMsg: {
    fontSize: 16,
    color: '#FF3399'
  },
  headerText: {
    fontSize: 16,
  },
  subText: {
    fontSize: 14,
  },
  deliveryPromo: {
    color: 'red',
  },
  fee: {
    backgroundColor: '#2ACB96',
    color: 'white',
    textAlign: 'center',
    padding: 5,
    margin: 5,
    fontSize: 18
  }
}));
