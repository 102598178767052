import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  toolbar: theme.mixins.toolbar,
  layout: {
    marginTop: '5%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  divider: {
    margin: '20px 0',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    margin: '0px 10px',
    width: '100%'
  },
  dateContainer: {
    margin: '12px 10px',
  },
  pinkNotes: {
    color: '#ec407a',
    marginLeft: '12px'
  },
  lineHeight: {
    lineHeight: '2.6'
  },
  table: {
    textAlign: 'left',
    width: '100%',
  },
  tableContentId: {
    padding: '10px',
    width: '5%',
  },
  tableContentItem: {
    padding: '10px',
    width: '75%',
  },
  tableContentQty: {
    padding: '10px',
    width: '20%',
  },
  scheduleDelivery: {
    backgroundColor: 'red',
    padding: 10,
    color: 'white',
  },
  schedulePickup: {
    backgroundColor: '#15A659',
    padding: 10,
    color: 'white',
  },
  scheduleWrap: {
    paddingLeft: 10,
    paddingRight: 10,
    textTransform: 'capitalize',
  },
  scheduleCustomer: {
    backgroundColor: '#FFBAED',
    paddingLeft: 10,
    paddingRight: 10,
  },
  scheduleMsg: {
    backgroundColor: '#BADAFF',
    paddingLeft: 10,
    paddingRight: 10,
  },
  scheduleRemarks: {
    backgroundColor: '#FFD88E',
    paddingLeft: 10,
    paddingRight: 10,
  },
  spacer: {
    paddingBottom: 50,
    backgroundColor: 'white'
  },
  scheduleGroup: {
    display: 'flex',
    flexDirection: 'row'
  },
  scheduleDate: {
    flex: 1,
  },
  scheduleSection: {
    flex: 5,
    backgroundColor: '#F2F2F2'
  },
  orderRow: {
    verticalAlign: 'top'
  }
}));
