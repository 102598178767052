import React, { useState, useEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Navbar, Products, Cart, Checkout, Order, Login, OrderSchedules } from './components';
import cloneDeep from 'lodash/cloneDeep';
import useToken from './components/useToken';
import { API_URL } from './components/commons';

// const BASE_URL = process.env.REACT_APP_BASE_URL;
global.console.log = function() {};

const App = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [cart, setCart] = useState({total_items:0, line_items:[], subtotal:0});
  const [bento, setBento] = useState({total_items:0, first_order:""});
  const [order, setOrder] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [peakPeriod, setPeakPeriod] = useState([]);
  const [promocodes, setPromocodes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const mixMatchTitle = ["Kueh Lapis", "Kueh Salat", "Kueh Bingka", "Pulut Inti", "Kueh Dadar"];      
  const chMixMatchTitle = ["Kueh Lapis (九层糕)", "Kueh Salat (沙拉糕)", "Kueh Bingka (香烤木薯糕)", "Pulut Inti (椰糖丝糯米角)", "Kueh Dadar (椰糖丝薄饼)"];      

  const { token, setToken, unsetToken } = useToken();
  
  const fetchProducts = async () => {
    fetch(API_URL+"/products", {
      "method": "GET",
      "headers": {
        "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
      }})
      .then((res) => res.json())
      .then((data) => { console.log(data);

        fetch(API_URL+"/promotions", {
          "method": "GET",
          "headers": {
            "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
          }})
          .then((res2) => res2.json())
          .then((promotions) => { console.log(promotions);
            setPromotions(promotions);

            for (let i = 0; i < promotions.length; i++) {
              let today = new Date();
              let startDate = new Date(promotions[i].startDate);
              let endDate = new Date( promotions[i].endDate);

              // proceed only if within promotion period - add msg
              if(startDate.getTime() <= today.getTime() && endDate.getTime() >= today.getTime()){
                let categoryProducts = data.find(item => item.id === promotions[i].productCategory);
                if (categoryProducts) {
                  for (let j = 0; j < categoryProducts.products.length; j++) {
                    if (!JSON.parse(promotions[i].exclude).find(item => item === categoryProducts.products[j].productID)) {
                      categoryProducts.products[j].msg = promotions[i].msg;
                      categoryProducts.products[j].cMsg = promotions[i].cMsg;
                    }
                  }
                }
              }
            }

            setProducts(data);
          });
    
    });
    // setProducts(data);
  };

  const fetchCart = async () => {
    return cart;
  };

  const handleSetSelection = async (categoryId, productId, selection) => {
    // console.log("handleSetSelection");
    // console.log(categoryId);
    // console.log(productId);
    // console.log(selection.name);
    const category = products.find(item => item.id === categoryId);
    const product = category.products.find(item => item.productID === productId);
    product.selected = parseInt(selection);
    setProducts([...products]);
  };

  const handleAddToCart = async (categoryId, productId, quantity, mixMatchArray, checkboxOptions, radioOptions) => {
    console.log("quantity: "+quantity);
    let cartProductId = categoryId+"-"+productId;
    const category = products.find(item => item.id === categoryId);
    let product = cloneDeep(category.products.find(item => item.productID === productId));

    // selection for segment eg. 6 inch
    console.log('water');
    console.log(product.selected);
    console.log(product.selectionMode);
    if (product.selectionMode) {
      product = JSON.parse(product.selectionMode)[product.selected];
      cartProductId += "-"+product.id;
      console.log(product);
    }

    // selection for stepper eg. mix and match
    if (product.stepperMode) {
      if (mixMatchArray) {
        product.config = "";
        product.cConfig = "";
        for (var i = 0; i < mixMatchArray.length; i++) {
          cartProductId += "-"+mixMatchArray[i];
          if (mixMatchArray[i] !== 0) {
            product.config += mixMatchTitle[i]+" x "+mixMatchArray[i]+"<br />";
            product.cConfig += chMixMatchTitle[i]+" x "+mixMatchArray[i]+"<br />";
          }
        }
        // add ondeh ondeh
        product.config += "Ondeh Ondeh x 1 pair";
        product.cConfig += "Ondeh Ondeh(爆浆椰糖球) x 1对";

        console.log('mixMatchArray - config');
        console.log(product.config);
      }
    }

    // radio mode
    if (radioOptions) {
      let radioSelection = (JSON.parse(product.radioMode))[radioOptions-1];
      cartProductId += "-"+radioSelection.id;
      product.config = radioSelection.name;
      product.cConfig = radioSelection.cName;
    }

    // checkbox selected
    cartProductId += checkboxOptions?'-1':'';
    product.config = checkboxOptions?'(No Sesame Seeds)':product.config;
    product.cConfig = checkboxOptions?'(不要芝麻)':product.cConfig;

    console.log("cartProductId: "+cartProductId);

    // bento cakes
    let firstOrder = bento.first_order;
    let bentoTotal = bento.total_items;
    if (cartProductId.startsWith("5-11-")) {
      if (bentoTotal === 0) {
        firstOrder = cartProductId;
      }
      bentoTotal = bento.total_items + quantity;
      setBento({"total_items": bentoTotal, first_order:firstOrder});
      console.log("bentoTotal "+bentoTotal);
      console.log("first_order "+firstOrder);
    }
    
    let productInCart = cart.line_items.find(item => item.productID === cartProductId);
    if (productInCart) {
      console.log("add on product");
      console.log(product);

      const price = productInCart.priceFormatted;
      let subtotal = cart.subtotal-productInCart.line_total;
      
      productInCart.quantity = productInCart.quantity + quantity;
      productInCart.optionMsg = checkboxOptions?productInCart.checkboxMsg:null;
      // discount eg. 2 for
      productInCart.line_total = product.discountUnit ? (Math.floor(productInCart.quantity/product.discountUnit)*product.discount)+((productInCart.quantity%product.discountUnit)*price):productInCart.quantity * price;
      
      // new discount for 2 or more for xmas bento cakes
      if (product.discountUnit === 99 && bentoTotal > 1) {
        productInCart.line_total = productInCart.quantity * 25;
      }
      
      subtotal = subtotal+productInCart.line_total;
      setCart({"total_items": cart.total_items+quantity, "line_items": [...cart.line_items], "subtotal":subtotal});
    } else {
      console.log("new product");
      // check for product promo
      let productPromo = promotions.find(item => item.productCategory === categoryId);
      let price = product.priceFormatted;
      console.log(productId);
      if (productPromo && !(JSON.parse(productPromo.exclude).find(item => item === productId))) {
        let today = new Date();
        let startDate = new Date(productPromo.startDate);
        let endDate = new Date( productPromo.endDate);
        console.log(today);
        console.log(startDate);
        console.log(endDate);

        // proceed only if within period
        if(startDate.getTime() <= today.getTime() && endDate.getTime() >= today.getTime()){
          console.log("promotional price!");
          if (productPromo.type === "percentage") {
            product.promotionTitle = productPromo.name + " (Usual Price: S$"+price+")";
            product.cPromotionTitle = productPromo.cName + " (原价: S$"+price+")";
            price = price * ((100-productPromo.amount)/100);
          }
        }
      }

      product.optionMsg = checkboxOptions?product.checkboxMsg:null;
      product.productID = cartProductId;
      console.log(cartProductId);

      product.priceFormatted = price;
      product.quantity = quantity;

      // discount eg. 2 for
      product.line_total = product.discountUnit ? (Math.floor(quantity/product.discountUnit)*product.discount)+((quantity%product.discountUnit)*price):quantity * price;
      
      // new discount for 2 or more for xmas bento cakes
      if (product.discountUnit === 99 && bentoTotal > 1) {
        product.line_total = quantity * 25;
      }

      let bentoPromoTotal = 0;
      if (product.productID !== bento.first_order && bento.first_order !== "") {
        let firstBentoInCart = cart.line_items.find(item => item.productID === bento.first_order);
        if (firstBentoInCart.line_total > 25) {
          firstBentoInCart.line_total = 25.00;
          bentoPromoTotal = -3;
        }
      }
      
      const subtotal = cart.subtotal+product.line_total+bentoPromoTotal;
      setCart({"total_items": cart.total_items+quantity, "line_items": [...cart.line_items, product], "subtotal":subtotal});
    }
  };

  const handleUpdateCartQty = async (lineItemId, quantity) => {
    console.log("update la");
    if (quantity > 0) {
      var productInCart = cart.line_items.find(item => item.productID === lineItemId);
      
      var subtotal = cart.subtotal-productInCart.line_total;
      const difference = quantity - productInCart.quantity;
      productInCart.quantity = quantity;
      productInCart.line_total = productInCart.discountUnit ? (Math.floor(quantity/productInCart.discountUnit)*productInCart.discount)+((quantity%productInCart.discountUnit)*productInCart.priceFormatted):quantity * productInCart.priceFormatted;
      
      // new discount for 2 or more for xmas bento cakes
      let bentoTotal = bento.total_items + difference;
      setBento({"total_items": bentoTotal, "first_order": bento.first_order});
      console.log("bentoTotal "+bentoTotal);
      if (productInCart.discountUnit === 99 && bentoTotal > 1) {
        productInCart.line_total = quantity * 25;
      }

      subtotal = subtotal+productInCart.line_total;
      
      setCart({"total_items": cart.total_items+difference, "line_items": [...cart.line_items], "subtotal":subtotal});
    }
  };

  const handleRemoveFromCart = async (lineItemId) => {
    var index = cart.line_items.findIndex(item => item.productID === lineItemId);
    var array = [...cart.line_items];
    const quantity = array[index].quantity;
    const lineTotal = array[index].line_total;
    const productId = array[index].productID;
    array.splice(index, 1);

    let bentoDiff = 0;
    // bento cakes
    if (productId.startsWith("5-11-")) {
      console.log('removed '+productId);
      console.log('first bento '+bento.first_order);

      let bentoTotal = bento.total_items - quantity;

      // if firstBento is removed, set to next
      if (productId === bento.first_order) {
        console.log(array);
        let nextFirstBento  = array.find(element => element.productID.startsWith("5-11-"));
        let nextFirstBentoIndex = array.findIndex(item => item.productID === nextFirstBento.productID);;
        console.log('index'+nextFirstBentoIndex);
        if (nextFirstBento) {
          console.log('next bento '+nextFirstBento.productID);
          console.log('set next bento '+nextFirstBento.productID);
          let newBento = array[nextFirstBentoIndex];
          if (bentoTotal === 1) {
            newBento.line_total = 28.00;
            bentoDiff = 3;
          }
          array[nextFirstBentoIndex] = newBento;
          setBento({total_items:bentoTotal, first_order:nextFirstBento.productID});
        } else {
          console.log('no next bento');
          setBento({total_items:0, first_order:""});
        }
      } else { // this is not the first bento!
        console.log("did not remove first bento: "+ bentoTotal);
        if (bentoTotal === 1) {
          let firstBentoIndex = array.findIndex(item => item.productID === bento.first_order);;
          let newBento = array[firstBentoIndex];
          newBento.line_total = 28.00;
          bentoDiff = 3;
          array[firstBentoIndex] = newBento;
        }
        setBento({total_items:bentoTotal, first_order:bento.first_order})
      }
    }
    
    setCart({"total_items": cart.total_items-quantity, "line_items": array, "subtotal":cart.subtotal-lineTotal+bentoDiff});
  };

  const handleEmptyCart = async () => {
    setCart({total_items:0, line_items:[], subtotal:0});
  };

  const fetchStatus = async () => {
    fetch(API_URL+"/status", {
      "method": "GET",
      "headers": {
        "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .then(response => response.json())
    .then(response => {
      console.log('set status');
      setStatusList(response);
    })
    .catch(err => {
      console.log(err);
    });
  };

  const fetchPeakPeriod = async () => {
    fetch(API_URL+"/peakperiod", {
      "method": "GET",
      "headers": {
        "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .then(response => response.json())
    .then(response => {
      setPeakPeriod(response);
    })
    .catch(err => {
      console.log(err);
    });
  };

  const fetchDeliveryPromo = async () => {
    fetch(API_URL+"/deliverypromo", {
      "method": "GET",
      "headers": {
        "accesstoken": process.env.REACT_APP_ACCESS_TOKEN
      }
    })
    .then(response => response.json())
    .then(response => {
      setPromocodes(response);
    })
    .catch(err => {
      console.log(err);
    });
  };

  useEffect(() => {
    fetchProducts();
    // setProducts(prd);
    fetchCart();
    fetchStatus();
    fetchPeakPeriod();
    fetchDeliveryPromo();
  }, []);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  let ordersLoginReq = <Order unsetToken={unsetToken} token={token} statusList={statusList} />;
  let schedulesLoginReq = <OrderSchedules unsetToken={unsetToken} token={token} statusList={statusList} />;
  if(!token) {
    ordersLoginReq = <Login setToken={setToken} />;
    schedulesLoginReq = <Login setToken={setToken} />;
  }
  
  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar totalItems={cart.total_items} handleDrawerToggle={handleDrawerToggle} />
        <Switch>
          <Route exact path="/">
            <Products products={products} onAddToCart={handleAddToCart} onUpdateSelection={handleSetSelection} handleUpdateCartQty />
          </Route>
          <Route exact path="/cart">
            <Cart cart={cart} onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart} onEmptyCart={handleEmptyCart} />
          </Route>
          <Route path="/checkout" exact>
            <Checkout cart={cart} order={order} peakPeriod={peakPeriod} promocodes={promocodes} handleEmptyCart={handleEmptyCart} error={errorMessage} />
          </Route>
          <Route path="/adminOrders=:id" exact>
            {ordersLoginReq}
          </Route>
          <Route path="/adminOrdersSchedule" exact>
            {schedulesLoginReq}
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
